import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import './Checkout.css';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { cartContext } from '../Context/CartContext';

export const Facturacion = ({
  user,
  info,
  enviarOrden,
  infoInputs,
  condPago,
  setCondPago,
  tiposDeEntrega,
  setTiposDeEntrega,
  actualizarCondicionesDePagoYEntrega,
  actualizando,
  setActualizando

}) => {
  const [defaultValue, setDefaultValue] = useState('Retira personalmente');
  const [defaultValueUsa, setDefaultValueUsa] = useState('Pickup');
  const [showFileInput, setShowFileInput] = useState(false);
  const [pago, setPago] = useState(info.pago || 'efectivo');
  const urlApiPedidos = process.env.REACT_APP_API_PEDIDOS
  const { setSelectedFile } = useContext(cartContext)
  const [t] = useTranslation("global");
  const [fileUpdated, setFileUpdated] = useState(false);
  const [esPickup, setEsPickup] = useState(false)
  const [esPrePay, setEsPrepay] = useState(false)


  const country = process.env.REACT_APP_COUNTRY;
  let rucPais = country === "PY" ? "RUC" : "DNI/CUIL";

  useEffect(() => {
    infoInputs({ target: { name: 'entrega', value: defaultValue } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  // Guardo los tipos de entrega y las condiciones de pago
  const saveCondicionesPagoEntrega = async () => {
    const storedTiposDeEntrega = localStorage.getItem('tiposDeEntrega');
    const storedCondPago = localStorage.getItem('condPago');

    if (storedTiposDeEntrega && storedCondPago) {
      setTiposDeEntrega(JSON.parse(storedTiposDeEntrega));
      setCondPago(JSON.parse(storedCondPago));
    } else {
      try {
        const [response1, response2] = await Promise.all([
          axios.get(`${urlApiPedidos}/constantes/condiciones_pago`),
          axios.get(`${urlApiPedidos}/constantes/tipos_pedido`)
        ]);
        setTiposDeEntrega(response2.data);
        setCondPago(response1.data);
        localStorage.setItem('tiposDeEntrega', JSON.stringify(response2.data));
        localStorage.setItem('condPago', JSON.stringify(response1.data));
      } catch (error) {
        console.error("Error al obtener los tipos de entrega:", error);
      }
    }
  };

  useEffect(() => {
    saveCondicionesPagoEntrega();
  }, []);




  useEffect(() => {
    actualizarCondicionesDePagoYEntrega();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.entrega, info.pago]);

  console.log(info.entrega, "info entrega")

  // Handler para options de pago
  const handlePagoChange = (e) => {
    const value = e.target.value;
    const selectedPago = condPago.find(pay => pay.Descripcion === value);
    setPago(value);
    setShowFileInput(info.pago === 'PRE PAY' && info.entrega === "RETIRA");
    infoInputs({ target: { name: 'pago', value } });

    //Logica para que si cambio de metodo de pago se borre el comprobante del almacenamiento de sesion
    if (value !== '02') {
      setSelectedFile(null);
      setFileUpdated(false);
    }

    // USA
    if (country === "USA" && selectedPago && selectedPago.Codigo === "02") {
      setEsPrepay(true);
    } else {
      setEsPrepay(false);
    }
  };


  // Handler para options de la entrega 
  const handleEntregaChange = (e) => {
    const value = e.target.value;
    const selectedEntrega = tiposDeEntrega.find(entrega => entrega.Descripcion === value);

    setDefaultValue(value);
    infoInputs({ target: { name: 'entrega', value } })


    if (country === "USA" && selectedEntrega && selectedEntrega.Codigo === "P") {
      setEsPickup(true);
    } else {
      setEsPickup(false);
    }
  };

  console.log(esPickup, "Es pickup")
  console.log(esPrePay, "Es prepay")

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setFileUpdated(true);

      const fileData = {
        name: file.name,
        type: file.type,
        size: file.size,
        content: ''
      };

      const reader = new FileReader();
      reader.onload = () => {
        const base64Content = reader.result.split(',')[1]; // Split 
        fileData.content = base64Content;
        setSelectedFile(fileData);
      };
      reader.readAsDataURL(file); //Lo lee como base 64
    } else {
      setSelectedFile(null);
      setFileUpdated(false);
    }
  };



  useEffect(() => {
    if (info.pago !== '02') {
      setFileUpdated(false);
    }
  }, [info.pago]);

  console.log(tiposDeEntrega, "estos son los tipos de entrega")
  console.log(condPago, "estos son las condiciones de pago")

  return (
    <>
      <div className='card-body cardBody'>
        <h3>{t("facturacion.datos")}</h3>
        <form onSubmit={enviarOrden}>
          <div className='datosForm'>
            <p><b>{t("facturacion.nombre")}:</b> {user.Nombre}</p>
            <p><b>{t("facturacion.apellido")}: </b>{user.Apellido}</p>
            <p><b>{t("formulario.label-2")}: </b>{user.Email}</p>
            <p><b>{rucPais}: </b>{user.Cuit}</p>
          </div>
          <div className='opcionesForm'>
            <label htmlFor='entrega'>{t("facturacion.opciones-entrega")}</label>
            <select value={info.entrega} name='entrega' onChange={handleEntregaChange}>
              <option value='Retira personalmente'>Seleccione...</option>
              {tiposDeEntrega.map((entrega) => (
                <option key={entrega.Codigo} value={entrega.Descripcion}>{entrega.Descripcion}</option>
              ))}
            </select>

          </div>
          <div className='datosForm'>
            <hr />
            <h3>{t("facturacion.direccion-declarada")}</h3>
            <h6>{t("facturacion.podra-coordinar")}</h6>
            <br />
            <p><b>{t("formulario.direccion")}: </b>{info.entrega === "ENTREGA" ? user.Domicilio_entrega.Domicilio : user.Domicilio_facturacion.Domicilio}</p>
            <p><b>{t("facturacion.codigo-postal")}: </b>{info.entrega === "ENTREGA" ? user.Domicilio_entrega.Codigo_postal : user.Domicilio_facturacion.Codigo_postal || 'No informado'}</p>
            <p><b>{t("facturacion.localidad")}: </b>{info.entrega === "ENTREGA" ? user.Domicilio_entrega.Localidad : user.Domicilio_facturacion.Localidad}</p>
            <p><b>{t("facturacion.pais")}: </b>{info.entrega === "ENTREGA" ? user.Domicilio_entrega.Pais : user.Domicilio_facturacion.Pais}</p>
            <hr />
          </div>
          <div className='opcionesForm'>
            <label htmlFor='pago'>{t("facturacion.opciones-pago")}</label>
            <select value={pago} name='pago' onChange={handlePagoChange}>
              <option value='efectivo'>Seleccione...</option>
              {condPago.map((condicion) => (
                <option key={condicion.Codigo} value={condicion.Descripcion}>{condicion.Descripcion}</option>
              ))}
            </select>
          </div>
          {/*Valido la muestra del input de carga de comprobante */}
          {info.entrega === "RETIRA" && info.pago === "PRE PAY" && (
            <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
              <label htmlFor='archivoTransferencia' style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Por favor, adjunte su comprobante de transferencia
              </label>
              <input
                type='file'
                name='archivoTransferencia'
                id='archivoTransferencia'
                onChange={handleFileChange}
                accept='.jpg, .png, .pdf'
                style={{
                  padding: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  width: '100%',
                  maxWidth: '300px',
                  textAlign: 'center'
                }}
              />
            </div>
          )}

          {esPickup && esPrePay && country === "USA" && (
            <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
              <label htmlFor='archivoTransferencia' style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Por favor, adjunte su comprobante de transferencia
              </label>
              <input
                type='file'
                name='archivoTransferencia'
                id='archivoTransferencia'
                onChange={handleFileChange}
                accept='.jpg, .png, .pdf'
                style={{
                  padding: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  width: '100%',
                  maxWidth: '300px',
                  textAlign: 'center'
                }}
              />
            </div>
          )}

          <div className='datosForm'>
            <hr />
            <h3>{t("facturacion.ultimo-paso")}</h3>
            <p>{t("facturacion.procesando-compra")}</p>
            <br />
            <hr />
          </div>
          {actualizando ? (
            <Button variant='success' type='submit' className='botonProcesarCompra' disabled>
              Procesando...
            </Button>
          ) : (
            <Button variant='success' type='submit' className='botonProcesarCompra' disabled={pago === 'efectivo' || info.entrega === "Retira personalmente" || (pago === '02' && !fileUpdated) || (info.pago === 'PRE PAY' && !fileUpdated)}>
              {t("facturacion.procesar-compra")}
            </Button>
          )}
        </form>
      </div>
    </>
  );
};


