
import React, { useContext } from 'react';
import './Ofertas.css';
import { useNavigate } from 'react-router';
import CardOferta from '../../components/CardOferta/CardOferta';
import OfertaBanner from '../../components/OfertaBanner/OfertaBanner';
import { SpinnerMarcas } from '../../components/helpers/SpinnerMarcas';
import FlexOfertas from '../../components/FlexOfertas/FlexOfertas';
import { SliderMarcas } from './../../components/SliderMarcas/SliderMarcas';
import useOfertas from '../../hooks/useOfertas';
import { authContext } from '../../components/Context/AuthContext';
import { useTranslation } from 'react-i18next';

const Ofertas = () => {
    const navigate = useNavigate();
    const { data, load, currentPage, handleNextPage, elementosPorPagina } = useOfertas();
    const { token } = useContext(authContext)

    const irADetalles = (param, id) => {
        const id_replaced = id.replace(/ç/g, '');
        navigate(`/detalle?sku=${id_replaced}`, {
            state: {
                param,
                id
            }
        });
    }

    const [t, i18next] = useTranslation("global")

    return (
        <div style={{ maxWidth: '1500px', margin: '0 auto', overflow: 'hidden' }} className='ofertas-view'>
            <div style={{
                position: 'relative',
                width: '100%',
                height: 'auto',
                marginBottom: '-10px',
            }}>
                <img
                    src={`${process.env.PUBLIC_URL}/banner/ofertas.png`}
                    alt="Banner Placeholder"
                    style={{ width: '100%', height: 'auto', display: 'block' }}
                />
                <div style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: '80px',
                    backgroundImage: 'linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0))',
                }} />
            </div>
            <FlexOfertas />
            <div className="ofertas-container" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', position: 'relative' }}>
                {load ? (
                    data.length > 0 && currentPage === 1 ? (
                        data.slice(0, elementosPorPagina).map((oferta, index) => (
                            <div key={index} className='oferta-map' onClick={() => irADetalles(oferta, oferta.Alias)}>
                                <CardOferta
                                    icon={<i className="fas fa-tag"></i>}
                                    nombre={oferta.Nombre}
                                    descripcion={oferta.Marca}
                                    imagen={oferta.Imagenes ? process.env.PUBLIC_URL + `/articulos/thumbs/${encodeURIComponent(oferta.Imagenes.split(',')[0])}` : ''}
                                    precio={oferta.Precio ? oferta.Precio : ''}
                                    stock={oferta.Precio ? oferta.Stock : ''}
                                />
                            </div>
                        ))

                    ) : currentPage !== 1 ? (
                        data.map((oferta, index) => (
                            <div key={index} className='oferta-map' onClick={() => irADetalles(oferta, oferta.Alias)}>
                                <CardOferta
                                    nombre={oferta.Nombre}
                                    descripcion={oferta.Marca}
                                    imagen={oferta.Imagenes ? process.env.PUBLIC_URL + `/articulos/thumbs/${encodeURIComponent(oferta.Imagenes.split(',')[0])}` : ''}
                                    precio={oferta.Precio}
                                    stock={oferta.Stock}
                                    icon={<i className="fas fa-tag"></i>}
                                />
                            </div>
                        ))
                    ) : (
                        <h1>{t("ofertas.no-hay-ofertas")}</h1>
                    )
                ) : (
                    <SpinnerMarcas />
                )}



            </div>
            {currentPage === 1 && data.length > 0 ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ margin: '0 auto' }} className='oferta-card-button'>
                        <button onClick={() => handleNextPage()}>{t("ofertas.ver-todas")}</button>
                    </div>
                </div>
            ) : ''}
            {!token && (
                <div className='oferta-banner-container'>
                    <OfertaBanner />
                </div>
            )}
            {/* <div style={{ maxWidth: '1500px', marginTop: '4em' }}>
                <SliderMarcas />
            </div> */}
        </div>
    );
}

export default Ofertas;
